import { useEffect, useState } from 'react';
import MainPlatform from '../components/main_platform';
import Loading from './loading';
import { qrRedirectOnPlatform } from '../../api/api';
import { PlatformInfo, SessionInfo } from '../../api/api_types';
import Platforms from '../components/platforms';
import { useAppDispatch, useAppSelector } from '../../store/store';
import AttributesView from './attributes_view';
import { setFailure } from '../../store/system';
import { selectAttributesThunk } from '../../store/qr';

export default function MainPlatformView(props: { sessionInfo: SessionInfo | undefined }) {
  console.log('MainPlatformView');
  const dispatch = useAppDispatch();
  const { sessionInfo } = props;
  const { loading, sessionId, failure, qrCodeId } = useAppSelector(
    (state) => ({
      loading: state.qr.loading,
      sessionId: state.qr.sessionId,
      failure: state.system.failure,
      qrCodeId: state.qr.sessionInfo?.qrCodeId,
    }),
  );
  const [redirectLoading, setRedirectLoading] = useState(true);
  const [isAllPlatformsPage, setAllPlatformsPage] = useState(false);
  const [isAttributesPage, setAttributesPage] = useState(
    sessionInfo?.attributes && sessionInfo?.attributes.length > 0,
  );

  function getPlatformId() {
    const platforms = sessionInfo?.rankPlatforms;
    if (platforms) {
      const redirectPlatform = platforms.find(
        (p) => p.rankPlatformType === sessionInfo.mainRankPlatformType,
      );
      return redirectPlatform?.rankPlatformId ?? '';
    }
    return '';
  }

  useEffect(() => {
    const checkGoogleAuth = async (): Promise<boolean> => {
      try {
        return await new Promise((res, rej) => {
          const img = new Image();
          img.src =
            'https://accounts.google.com/CheckCookie?continue=https%3A%2F%2Fwww.google.com%2Fintl%2Fen%2Fimages%2Flogos%2Faccounts_logo.png&followup=https%3A%2F%2Fwww.google.com%2Fintl%2Fen%2Fimages%2Flogos%2Faccounts_logo.png&chtml=LoginDoneHtml&checkedDomains=youtube&checkConnection=youtube%3A291%3A1';
          img.onload = () => res(true);

          img.onerror = () => rej(false);
        });
      } catch (error) {
        return false;
      }
    };

    function _getPlatformId(data: SessionInfo) {
      const platforms = data.rankPlatforms;
      if (platforms) {
        const redirectPlatform = platforms.find(
          (p) => p.rankPlatformType === data.mainRankPlatformType,
        );
        return redirectPlatform?.rankPlatformId ?? '';
      }
      return '';
    }

    const init = async () => {
      if (sessionInfo?.redirectOnRate) {
        // const googleAuth = await checkGoogleAuth().catch();
        qrRedirectOnPlatform(qrCodeId, _getPlatformId(sessionInfo));
        return;
      }
      setRedirectLoading(false);
    };
    init();
  }, [qrCodeId, sessionInfo, redirectLoading]);

  function onRedirect(rankPlatformId: string) { qrRedirectOnPlatform(qrCodeId, rankPlatformId); }

  const onAttributesClick = async (attributes: string[]) => {
    await dispatch(
      selectAttributesThunk({
        attributes: attributes,
        onReject: dispatch(setFailure(true)),
      }),
    );

    if (!failure) {
      setAttributesPage(false);
    }
  };

  if (redirectLoading || !sessionInfo) {
    return <Loading />;
  } else if (isAttributesPage) {
    return (
      <AttributesView
        attributes={sessionInfo?.attributes ?? []}
        onClick={onAttributesClick}
        loading={loading}
      />
    );
  } else if (
    sessionInfo?.mainRankPlatformType === null ||
    sessionInfo?.mainRankPlatformType === undefined ||
    isAllPlatformsPage
  ) {
    return (
      <Platforms
        rankPlatforms={sessionInfo?.rankPlatforms}
        onRedirect={(platform: PlatformInfo) => onRedirect(platform.rankPlatformId)}
      />
    );
  } else {
    return (
      <MainPlatform
        id={sessionId}
        mainRankPlatformType={sessionInfo?.mainRankPlatformType}
        rankPlatforms={sessionInfo?.rankPlatforms}
        onOtherClick={() => {
          setAllPlatformsPage(true);
        }}
        onRedirect={() => onRedirect(getPlatformId())}
      />
    );
  }
}
