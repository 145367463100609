import GoogleMapReact from 'google-map-react';
import { LocationCoordinate, Point, ReviewPoint } from '../../api/api_types';
import Marker from './marker';
import ReviewMarker from './review_marker';

export default function Map({
  points,
  visibility,
  isReviewChar,
  defaultZoom,
  initialCenter,
}: {
  points: Point[] | ReviewPoint[];
  visibility: boolean;
  isReviewChar: boolean;
  defaultZoom: number;
  initialCenter?: LocationCoordinate;
}) {
  const defaultProps = {
    center: {
      lat: initialCenter?.lat ?? (points[0] ? points[0].lat : 0),
      lng: initialCenter?.lng ?? (points[0] ? points[0].lng : 0),
    },
    zoom: defaultZoom - 1
  };

  const value = (point: Point | ReviewPoint) => {
    if ('position' in point) {
      return point.position;
    } else if ('rank' in point) {
      return point.reviewCount;
    }
  };

  function isCurrentPlace(point: any): point is ReviewPoint {
    return point.currentPlace === "boolean" && point.currentPlace;
  }

  const markerColor = (point: Point | ReviewPoint) => {
    if ('position' in point) {
      return;
    }

    if (point.hide) {
      return '#3780FF';
    } else if (!point.reviewCount) {
      return '#7d807d';
    } else if (point.rank < 0) {
      return '#38B137';
    } else if (point.rank === 0) {
      return '#8AB137';
    } else if (point.rank === 0.1) {
      return '#B1AC37';
    } else if (point.rank === 0.2) {
      return '#B18037';
    } else if (point.rank === 0.3) {
      return '#B16337';
    } else if (point.rank === 0.4) {
      return '#B14D37';
    } else if (point.rank === 0.5) {
      return '#B62727';
    } else if (point.rank >= 0.6) {
      return '#840000';
    }
  };

  return visibility ? (
    <div style={{
      height: '100%', width: '100%', borderRadius: '6px', aspectRatio: 1 / 1,
    }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY ?? '' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {points.map((item, index) => {
          return isReviewChar ? (
            <ReviewMarker
              key={index}
              lat={item.lat ?? 0}
              lng={item.lng ?? 0}
              value={value(item)}
              hide={item.hide}
              color={markerColor(item)}
              isCurrentPlace={isCurrentPlace(item)}
            />
          ) : (
            <Marker
              key={index}
              lat={item.lat ?? 0}
              lng={item.lng ?? 0}
              value={value(item)}
              hide={item.hide}
              color={markerColor(item)}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  ) : (
    <></>
  );
}
