import { Box, Typography } from "@mui/material";
import { PlaceInfo } from "../../api/api_types";

export default function LocationItem({
    item,
    isCurrentItem,
    selectedLocation,
    onClick,
}: {
    item?: PlaceInfo;
    isCurrentItem?: boolean;
    selectedLocation?: PlaceInfo;
    onClick: Function;
}) {
    const isSelected = selectedLocation === item;

    function getIcon(index: number) {
        const rate = item?.reviewRank ?? 0;
        const startNumber = index + 1;

        const remainder = +(rate % 1).toFixed(1);

        if (rate >= startNumber) {
            return '/images/audit/star_filled.svg';
        } else if (remainder >= 0.3 && remainder <= 0.7) {
            return '/images/audit/star_semi.svg';
        } else if (remainder > 0.7 && startNumber === Math.ceil(rate)) {
            return '/images/audit/star_filled.svg';
        } else {
            return '/images/audit/star_empty.svg';
        }
    }

    return (
        <Box
            onClick={() => onClick(item)}
            sx={{
                backgroundColor: isSelected ? '#e6e6e6' : '#fff',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                cursor: 'pointer',
                transition: 'all 0.3s',
                '&:hover': {
                    background: '#e6e6e6'
                }
            }}
        >
            <Box height={56} width={56} mr='8px'>
                <img
                    style={{
                        width: 56,
                        height: 56,
                        borderRadius: '6px',
                        objectFit: 'cover',
                    }}
                    alt=""
                    src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${item?.photoReference}&key=${process.env.REACT_APP_GOOGLE_KEY}`}
                />
            </Box>
            <Box maxWidth='310px'>
                <Typography
                    fontSize='14px'
                    fontWeight={400}
                    color={isCurrentItem ? '#3780FF' : '#000'}
                    overflow='hidden'
                    textOverflow='ellipsis'
                    whiteSpace='nowrap'
                >
                    {item?.name}
                </Typography>
                <Typography fontSize='14px' color='#C3C3C3' fontWeight={300}>
                    {item?.address}
                </Typography>
                <Box display='flex'>
                    {Array.from({ length: 5 }, (_, index) => (
                        <img key={index} src={getIcon(index)} alt="" />
                    ))}
                    <span
                        style={{ color: '#868686', fontSize: '12px', marginLeft: '4px' }}
                    >
                        ({item?.reviewRank})
                    </span>
                </Box>
            </Box>
        </Box>
    );
}
