import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

import { AuditInfo } from '../../api/api_types';
import { useParams } from 'react-router-dom';
import { getAuditInfo } from '../../api/api';
import './style.css';
import InfoLabel from './info_label';
import Title from './title';
import Services from './service';
import Map from './map';
import LocationAnalysis from './location_analysis';
import * as en from '../lang/en.json';
import * as pl from '../lang/pl.json';
import * as ua from '../lang/ua.json';
import * as de from '../lang/de.json';
import Analytics from './analytics';
import MainInfoBlock from './main_info_block';
import PositionedLocations from './location_item_with_position';


export function getColor(value?: number) {
  if (!value) {
    return '#C54A49';
  } else if (value < 4) {
    return '#38B137';
  } else if (value < 7) {
    return '#8AB137';
  } else if (value < 10) {
    return '#B1AC37';
  } else if (value < 13) {
    return '#B18037';
  } else if (value < 16) {
    return '#B16337';
  } else if (value < 19) {
    return '#B14D37';
  } else if (value < 20) {
    return '#B62727';
  } else {
    return '#C54A49';
  }
}

export function getBackgroundColor(value: number) {
  const color = getColor(value);
  const rgbColor = hexToRgb(color);
  const rgbaColor = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.15)`;
  return rgbaColor;
}

function hexToRgb(hex: string) {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

export default function AuditPage() {
  const { id } = useParams();
  const [auditInfo, setInfo] = useState<AuditInfo>();
  const [mapVisibility, setVisibility] = useState(false);
  const [lang, setLang] = useState(en);

  const isSmallScreen = useMediaQuery("(max-width: 720px)");

  const mainInfo = [
    lang.auditMainInfo1,
    lang.auditMainInfo2,
    lang.auditMainInfo3,
  ];

  const secondaryInfo = [
    lang.auditSecondaryInfo1,
    lang.auditSecondaryInfo2,
    lang.auditSecondaryInfo3,
  ];

  const date = new Date(auditInfo?.createdAt ?? '');

  const formattedDate = `${date.getDate()}.${date.getMonth() + 1
    }.${date.getFullYear()}`;

  useEffect(() => {
    async function init() {
      const info = await getAuditInfo(id ?? '');

      setInfo(info);

      switch (info?.language) {
        case 'En':
          setLang(en);
          break;
        case 'Pl':
          setLang(pl);
          break;
        case 'De':
          setLang(de);
          break;
        default:
          setLang(ua);
          break;
      }

      setVisibility(true);
    }

    init();
  }, []);

  return (
    <div
      style={{
        backgroundColor: '#F0F0F0',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '0 12px'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '50px',
          marginBottom: '22px',
        }}
      >
        <img src='/images/audit/logo.svg' alt='logo' />
        <h5
          style={{ fontWeight: 300, fontSize: '14px' }}
        >{`${lang.auditDate} ${formattedDate}`}</h5>
      </div>

      <h1
        style={{
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '24px',
          marginBottom: '6px',
        }}
      >
        {`${lang.auditSeo} “${auditInfo?.name}”`}
      </h1>
      <h5 style={{ fontWeight: 300, fontSize: '12px', marginBottom: '30px' }}>
        {auditInfo?.address}
      </h5>

      {auditInfo?.descriptionHtml && <>
        <Title text={lang.auditGoogle} fontSize='20px' />
        <div style={{
          lineHeight: '1.5'
        }} dangerouslySetInnerHTML={{ __html: auditInfo?.descriptionHtml }}></div>
      </>}
      <Title text={lang.auditAnalysis} fontSize='20px' />
      {isSmallScreen ?
        <div>
          <Grid>
            <Analytics keywords={auditInfo?.keywords} lang={lang} />
          </Grid>
          <Grid container spacing={1} style={{ marginTop: "6px" }}>
            <Grid item xs={3}>
              <StatisticItem
                icon='/images/audit/audit_icon_1.svg'
                title={auditInfo?.searchPosition ?? '21+'}
                subtitle={lang.average}
              />
            </Grid>
            <Grid item xs={3}>
              <StatisticItem
                icon='/images/audit/audit_icon_2.svg'
                title={`${auditInfo?.shareTopPositionRequests}%`}
                subtitle={lang.topRequest}
              />
            </Grid>
            <Grid item xs={3}>
              <StatisticItem
                icon='/images/audit/audit_icon_3.svg'
                title={auditInfo?.competitors}
                subtitle={lang.competitors}
              />
            </Grid>
            <Grid item xs={3}>
              <StatisticItem
                icon='/images/audit/audit_icon_4.svg'
                title={auditInfo?.winnedCompetitors}
                subtitle={lang.lowerRatedCompetitors}
              />
            </Grid>
          </Grid>
        </div>
        : <Grid container spacing={2}>
          <Grid item xs={6}>
            <Analytics keywords={auditInfo?.keywords} lang={lang} />
          </Grid>
          <Grid item xs={6} container spacing={1}>
            <Grid item xs={6}>
              <StatisticItem
                icon='/images/audit/audit_icon_1.svg'
                title={auditInfo?.searchPosition ?? '21+'}
                subtitle={lang.average}
              />
            </Grid>
            <Grid item xs={6}>
              <StatisticItem
                icon='/images/audit/audit_icon_2.svg'
                title={`${auditInfo?.shareTopPositionRequests}%`}
                subtitle={lang.topRequest}
              />
            </Grid>
            <Grid item xs={6}>
              <StatisticItem
                icon='/images/audit/audit_icon_3.svg'
                title={auditInfo?.competitors}
                subtitle={lang.competitors}
              />
            </Grid>
            <Grid item xs={6}>
              <StatisticItem
                icon='/images/audit/audit_icon_4.svg'
                title={auditInfo?.winnedCompetitors}
                subtitle={lang.lowerRatedCompetitors}
              />
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <PositionedLocations
              currentPlace={auditInfo?.currentPlace}
              competitorPlaces={auditInfo?.competitorPlaces}
              lang={lang}
            />
          </Grid>
          <Grid item xs={7}>
            <Map
              visibility={mapVisibility}
              isReviewChar={false}
              points={auditInfo?.points ?? []}
              defaultZoom={auditInfo?.defaultMapZoom ?? 12.8}
              initialCenter={auditInfo?.initialCenter}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoLabel info={mainInfo} />
          </Grid>
          <MainInfoBlock auditInfo={auditInfo} lang={lang} mapVisibility={mapVisibility} />
        </Grid>}
      <InfoLabel info={secondaryInfo} />
      <LocationAnalysis info={auditInfo?.pageOptimizationItems ?? []} />
      {auditInfo?.services && auditInfo?.services.length > 0 && <Services services={auditInfo?.services} />}
      <Box height={25}></Box>
    </div>
  );
}

interface ItemProps {
  icon: string;
  title?: string | number;
  subtitle: string;
}

function StatisticItem(props: ItemProps) {
  const { icon, title, subtitle } = props;
  return (
    <div
      style={{
        backgroundColor: '#fff',
        height: '58px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '6px',
        padding: '14px 12px',
      }}
    >
      <img src={icon} alt='' />
      <Box ml={'10px'}>
        <Typography fontSize={'22px'} fontWeight={500} lineHeight={'24px'}>
          {title}
        </Typography>
        <Typography
          fontSize={'12px'}
          fontWeight={4}
          lineHeight={'10px'}
          color={'#868686'}
        >
          {subtitle}
        </Typography>
      </Box>
    </div>
  );
}
