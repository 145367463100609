import { Box } from '@mui/material';

export default function ReviewMarker(props: {
  lat: number;
  lng: number;
  value?: number;
  rate?: number;
  color?: string;
  isCurrentPlace: boolean;
  selected: boolean;
}) {
  const value = () => props?.value ?? '';

  const width = props.selected ? '50px' : '24px';
  const height = props.selected ? '50px' : '24px';

  function getColor(value?: number) {
    if (!value || value <= 0) {
      return '#868686';
    } else if (value < 2) {
      return '#840000';
    } else if (value < 3) {
      return '#B62727';
    } else if (value < 3.5) {
      return '#B14D37';
    } else if (value < 4) {
      return '#B16337';
    } else if (value < 4.4) {
      return '#B18037';
    } else if (value < 4.7) {
      return '#8AB137';
    } else if (value === 5) {
      return '#38B137';
    } else {
      return '#840000';
    }
  }

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      sx={{
        position: 'relative',
        zIndex: props.selected ? 999 : 1,
        backgroundColor: props.selected ? 'blue' : getColor(props.rate),
        borderRadius: 50,
        height: height,
        width: width,
        color: '#fff',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {value()}
    </Box>
  );
}
