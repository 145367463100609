import { Box } from "@mui/material";
import { ScatterChart } from "@mui/x-charts";
import * as en from '../lang/en.json';
import * as pl from '../lang/pl.json';
import * as ua from '../lang/ua.json';
import * as de from '../lang/de.json';
import { AuditInfo, ChartPoint, PlaceInfo } from "../../api/api_types";

interface ChartProps {
    auditInfo?: AuditInfo;
    selectedLocation?: PlaceInfo;
    lang: typeof en | typeof ua | typeof de | typeof pl;
}

interface Spot {
    lat: number;
    lng: number;
}

export default function Chart(props: ChartProps) {
    const { lang, auditInfo, selectedLocation } = props;

    const spots = auditInfo?.competitorPlaces.map((e) => ({ 'lat': e.lat, 'lng': e.lng })) as Spot[];


    function getChartPoint(info?: ChartPoint) {
        return [
            {
                x: info?.reviews ?? 0,
                y: info?.reviewRank ?? 0,
                id: `${info?.reviews} ${info?.reviewRank}`,
            },
        ];
    }

    return <Box
        width='100%'
        height={400}
        sx={{
            backgroundColor: '#fff',
            borderRadius: '6px',
            padding: '14px 12px',
            mr: '16px',
        }}
    >
        <ScatterChart
            margin={{ top: 30, bottom: 60 }}
            xAxis={[{ label: lang.reviews }]}
            yAxis={[{ label: lang.rating }]}
            series={[
                {
                    label: lang.competitors,
                    color: '#000',
                    data:
                        auditInfo?.competitorPlaces.map((e) => ({
                            x: e.reveiwCount,
                            y: e.reviewRank,
                            id: `${e.reveiwCount} ${e.reviewRank}`,
                        })) ?? [],
                },
                {
                    label: auditInfo?.name,
                    color: '#3780FF',
                    data: auditInfo ?
                        [{
                            x: auditInfo?.currentPlace.reveiwCount,
                            y: auditInfo?.currentPlace.reviewRank,
                            id: `${auditInfo?.currentPlace.reveiwCount} ${auditInfo?.currentPlace.reviewRank}`,
                        }] : [],
                },
            ]}
        />
    </Box>
}