import { Box, Typography } from "@mui/material";
import { PlaceInfo } from "../../api/api_types";
import { getBackgroundColor, getColor } from "./audit_page";
import * as en from '../lang/en.json';
import * as pl from '../lang/pl.json';
import * as ua from '../lang/ua.json';
import * as de from '../lang/de.json';

export default function LocationItem({
    item,
    isCurrentItem,
    lang,
}: {
    item?: PlaceInfo;
    isCurrentItem?: boolean;
    lang: typeof en | typeof ua | typeof de | typeof pl;
}) {
    function getIcon(index: number) {
        const rate = item?.reveiwRank ?? 0;
        const startNumber = index + 1;

        const remainder = +(rate % 1).toFixed(1);

        if (rate >= startNumber) {
            return '/images/audit/star_filled.svg';
        } else if (remainder >= 0.3 && remainder <= 0.7) {
            return '/images/audit/star_semi.svg';
        } else if (remainder > 0.7 && startNumber === Math.ceil(rate)) {
            return '/images/audit/star_filled.svg';
        } else {
            return '/images/audit/star_empty.svg';
        }
    }

    return (
        <div
            style={{
                backgroundColor: '#fff',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
            }}
        >
            <Box height={56} width={56} mr='8px'>
                <img
                    style={{
                        width: 56,
                        height: 56,
                        borderRadius: '6px',
                        objectFit: 'cover',
                    }}
                    alt=""
                    src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${item?.photoReference}&key=${process.env.REACT_APP_GOOGLE_KEY}`}
                />
            </Box>
            <Box maxWidth='310px'>
                <Box display='flex'>
                    <Box
                        display='flex'
                        alignItems='center'
                        sx={{
                            backgroundColor: getBackgroundColor(item?.position ?? 0),
                            borderRadius: '4px',
                        }}
                        mr='3px'
                        p='2px 4px'
                    >
                        <h6
                            style={{
                                fontSize: '10px',
                                fontWeight: 400,
                                lineHeight: '8px',
                                color: getColor(item?.position ?? 0),
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {item?.position + lang.place}
                        </h6>
                    </Box>
                    <Typography
                        fontSize='12px'
                        fontWeight={400}
                        color={isCurrentItem ? '#3780FF' : '#000'}
                        overflow='hidden'
                        textOverflow='ellipsis'
                        whiteSpace='nowrap'
                    >
                        {item?.name}
                    </Typography>
                </Box>
                <Typography fontSize='12px' color='#C3C3C3' fontWeight={300}>
                    {item?.address}
                </Typography>
                <Box display='flex'>
                    {Array.from({ length: 5 }, (_, index) => (
                        <img key={index} src={getIcon(index)} alt="" />
                    ))}
                    <span
                        style={{ color: '#868686', fontSize: '10px', marginLeft: '4px' }}
                    >
                        ({item?.reveiwRank})
                    </span>
                </Box>
            </Box>
        </div>
    );
}
