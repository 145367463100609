import GoogleMapReact from 'google-map-react';
import { LocationCoordinate, PlaceInfo, Point, ReviewPoint } from '../../api/api_types';
import Marker from './marker';
import ReviewMarker from './review_marker';

export default function Map({
  points,
  visibility,
  isReviewChar,
  defaultZoom,
  initialCenter,
  selectedLocation,
}: {
  points: Point[] | PlaceInfo[];
  visibility: boolean;
  isReviewChar: boolean;
  defaultZoom: number;
  initialCenter?: LocationCoordinate;
  selectedLocation?: PlaceInfo;
}) {
  const defaultProps = {
    center: {
      lat: initialCenter?.lat ?? (points[0] ? points[0].lat : 0),
      lng: initialCenter?.lng ?? (points[0] ? points[0].lng : 0),
    },
    zoom: defaultZoom - 1
  };

  const value = (point: Point | PlaceInfo) => {
    if ('reveiwCount' in point) {
      return point.reveiwCount;
    } else {
      return point.position;
    }
  };

  const rank = (point: Point | PlaceInfo) => {
    if ('reviewRank' in point) {
      return point.reviewRank;
    }
  }

  function isCurrentPlace(point: any): point is PlaceInfo {
    return point.currentPlace === "boolean" && point.currentPlace;
  }

  const markerColor = (point: Point | PlaceInfo) => {
    if (!('reviewRank' in point)) {
      return;
    }

    if (!point.reveiwCount) {
      return '#7d807d';
    } else if (point.reviewRank < 0) {
      return '#38B137';
    } else if (point.reviewRank === 0) {
      return '#8AB137';
    } else if (point.reviewRank === 0.1) {
      return '#B1AC37';
    } else if (point.reviewRank === 0.2) {
      return '#B18037';
    } else if (point.reviewRank === 0.3) {
      return '#B16337';
    } else if (point.reviewRank === 0.4) {
      return '#B14D37';
    } else if (point.reviewRank === 0.5) {
      return '#B62727';
    } else if (point.reviewRank >= 0.6) {
      return '#840000';
    }
  };

  return visibility ? (
    <div style={{
      height: '100%', width: '100%', borderRadius: '6px', aspectRatio: 1 / 1,
    }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY ?? '' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {points.map((item, index) => isReviewChar ? (
          <ReviewMarker
            selected={selectedLocation === item}
            key={index}
            lat={item.lat ?? 0}
            lng={item.lng ?? 0}
            value={value(item)}
            rate={rank(item)}
            color={markerColor(item)}
            isCurrentPlace={isCurrentPlace(item)} />
        ) : (
          <Marker
            key={index}
            lat={item.lat ?? 0}
            lng={item.lng ?? 0}
            value={value(item)}
            hide={(item as Point).hide}
            color={markerColor(item)} />
        ))}
      </GoogleMapReact>
    </div>
  ) : (
    <></>
  );
}
