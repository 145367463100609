import { motion } from 'framer-motion';
import DefaultBtn from '../modules/default_btn';
import { useAppSelector } from '../../store/store';
import { Typography } from '@mui/material';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Loading from '../qr/loading';

interface FeedbackPageProps {
  onChange: (event: any) => void;
  onPhoneChange?: (val: any, mask: any) => void;
  onClick: () => void;
  loading: boolean;
  text: string;
  phone?: string;
  title?: string;
  question?: string;
  disabled: boolean;
  phoneError?: boolean;
}

export default function FeedbackPage(props: FeedbackPageProps) {
  const { onChange, onPhoneChange, onClick, loading, text, disabled, title, question, phoneError } = props;
  const lang = useAppSelector((state) => state.lang.lang);
  const [phoneCode, setPhoneCode] = useState('us');
  const [localLoading, setLoading] = useState(true);

  useEffect(() => {
    async function getCountryCode() {
      const response = await axios.get("https://ipapi.co/json/");

      setPhoneCode(response.data.country.toLowerCase() || 'us');
      setLoading(false);
    }

    if (onPhoneChange) {
      getCountryCode();
    }
  }, []);


  return (
    <motion.div
      className='feedback anim'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {localLoading ? <Loading /> : <>
        <h2 className='feedback__title'>{title ?? lang.feedbackTitle}</h2>
        <p className='feedback__text'>{question ?? lang.feedbackContent}</p>
        {onPhoneChange && <PhoneInput key={phoneCode} defaultCountry={phoneCode} onChange={onPhoneChange}
        />
        }
        {phoneError && <Typography className='phone__error'>{lang.phoneError}</Typography>}
        <textarea
          onChange={onChange}
          className='feedback__input'
          value={text}
          placeholder={lang.plaseholderSory}
          autoFocus={true}
        />
        <DefaultBtn
          disabled={disabled}
          loading={loading}
          onClick={onClick}
          text={lang.submit}
        /></>}
    </motion.div>
  );
}
