import { Grid, Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { AuditInfo, PlaceInfo } from "../../api/api_types";
import Chart from "./chart";
import LocationItem from "./location_item";
import * as en from '../lang/en.json';
import * as pl from '../lang/pl.json';
import * as ua from '../lang/ua.json';
import * as de from '../lang/de.json';
import Map from './map';

interface MainInfoBlockProps {
    auditInfo?: AuditInfo;
    mapVisibility: boolean,
    lang: typeof en | typeof ua | typeof de | typeof pl;
}

export default function MainInfoBlock(props: MainInfoBlockProps) {
    const [selectedLocation, setSelectedLocation] = useState<PlaceInfo | undefined>();

    const { auditInfo, mapVisibility, lang } = props;

    return <>
        <Grid item xs={5}>
            <Locations
                selectedLocation={selectedLocation}
                competitorPlaces={auditInfo?.competitorPlaces}
                selectLocation={setSelectedLocation}
                lang={lang}
            />
        </Grid>
        <Grid item xs={7}>
            <Grid item mb={'10px'}>
                <Chart lang={lang} auditInfo={auditInfo} selectedLocation={selectedLocation} />
            </Grid>
            <Grid>
                <Map
                    selectedLocation={selectedLocation}
                    visibility={mapVisibility}
                    points={auditInfo?.competitorPlaces ?? []}
                    isReviewChar={true}
                    defaultZoom={auditInfo?.defaultMapZoom ?? 12.8}
                    initialCenter={auditInfo?.initialCenter}
                />
            </Grid>
        </Grid>
    </>;
}

interface LocationsProps {
    competitorPlaces?: PlaceInfo[];
    selectedLocation?: PlaceInfo;
    selectLocation: Function;
    lang: typeof en | typeof ua | typeof de | typeof pl;
}

function Locations(props: LocationsProps) {
    const { competitorPlaces, lang, selectedLocation, selectLocation } = props;
    const [sortByCount, setSortByCount] = useState(true);

    const sortedList = () => {
        if (sortByCount) {
            return competitorPlaces?.sort((a, b) => b.reveiwCount - a.reveiwCount);
        } else {
            return competitorPlaces?.sort((a, b) => b.reviewRank - a.reviewRank);
        }
    }

    const handleClickOutside = () => {
        selectLocation(undefined);
    };

    useEffect(() => {
        const handleDocumentClick = () => handleClickOutside();

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    return (
        <Box onClick={((e) => e.stopPropagation())} display='flex' flexDirection='column' sx={
            {
                backgroundColor: '#fff',
                borderRadius: '6px',
                padding: '14px 12px',
                overflow: 'hidden',
            }
        }>
            <Box display='flex' alignItems='center'>
                <Typography color='#8D9097' p='0 30px'>
                    {lang.sortBy}
                </Typography>
                <Chip currentValue={sortByCount} value label={lang.reviewCount} onClick={(value: boolean) => setSortByCount(value)}></Chip>
                <Chip currentValue={sortByCount} value={false} label={lang.rank} onClick={(value: boolean) => setSortByCount(value)}></Chip>
            </Box>
            <div
                style={{
                    backgroundColor: '#fff',
                    borderRadius: '6px',
                    padding: '14px 12px',
                    overflow: 'hidden',
                }}
            >
                <div style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: '986px',
                }}>
                    {sortedList()?.map((item, index) => {
                        return <LocationItem item={item} key={index} selectedLocation={selectedLocation} onClick={(item: PlaceInfo) => selectLocation(item)} />;
                    })}
                </div>
            </div>
        </Box>
    );
}

function Chip(props: { label: string, value: boolean, onClick: Function, currentValue: boolean }) {
    const { label, value, onClick, currentValue } = props;
    const isSelected = value === currentValue;

    return <Box mr='12px' onClick={() => onClick(value)} sx={{
        borderRadius: '8px',
        backgroundColor: isSelected ? '#e6e6e6' : '#fff',
        p: '6px',
        color: isSelected ? '#000' : '#8d8d8d',
        cursor: 'pointer'
    }}>{label}</Box>
}
