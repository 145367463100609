import { Service } from '../../api/api_types';
import Title from './title';
import './style.css';
import { Box, Typography } from '@mui/material';

export default function Services(props: { services: Service[] }) {
  return (
    <div className='no-wrap_container'>
      <Title text='Рекомендуємі послуги' />
      {props.services.map((s, index) => (
        <Box
          className='no-wrap_container'
          key={index}
          sx={{
            backgroundColor: '#fff',
            borderRadius: '6px',
            padding: '30px',
            display: 'flex',
            alignItems: 'center',
            mb: '10px',
          }}
        >
          <Box>
            <Typography fontSize='20px' fontWeight={700} mb='20px'>
              {s.name}
            </Typography>
            <div
              style={{
                paddingRight: '16px',
                marginRight: '10px',
              }}
              dangerouslySetInnerHTML={{ __html: s.textHtml }}
            />
          </Box>
          <Box minWidth='150px'>
            <Typography fontSize='18px' fontWeight={700} color='#38B137'>
              Вартість
            </Typography>
            <Typography
              fontSize='32px'
              fontWeight={700}
              color='#38B137'
              whiteSpace='nowrap'
            >
              {s.price}
            </Typography>
          </Box>
        </Box>
      ))}
    </div>
  );
}
