import { Box, Typography } from '@mui/material';

export default function InfoLabel({ info }: { info: string[] }) {
  return (
    <Box
      display='flex'
      borderRadius='5px'
      alignItems='start'
      m='10px 0 30px 0'
      sx={{ backgroundColor: '#FFF4E1', p: '15px' }}
    >
      <img src='/images/audit/info.svg' alt='info' />
      <Box>
        {info.map((i, index) => (
          <Typography key={index} ml='20px' fontSize='12px' fontWeight={400}>
            {i}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
