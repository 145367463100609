import { Box } from "@mui/material";
import { KeywordsInfo } from "../../api/api_types";
import { getColor } from "./audit_page";
import * as en from '../lang/en.json';
import * as pl from '../lang/pl.json';
import * as ua from '../lang/ua.json';
import * as de from '../lang/de.json';

interface AnalyticsProps {
    keywords?: KeywordsInfo[];
    lang: typeof en | typeof ua | typeof de | typeof pl;
  }
  
  export default function Analytics(props: AnalyticsProps) {
    const { keywords, lang } = props;
  
    function getFormatedDistance(distance: number) {
      if (distance < 1000) {
        return `${distance}${lang.distanceM}`;
      } else {
        return `${distance / 1000}${lang.distanceKm}`;
      }
    }
  
    return (
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: '6px',
          padding: '14px 12px',
          height: '124px',
        }}
      >
        {keywords?.map((item, itemIndex) => {
          return (
            <div key={itemIndex}>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Box>
                  <h5 style={{ fontSize: '14px', fontWeight: 400 }}>
                    {item.keyword}
                  </h5>
                  <h6
                    style={{
                      fontSize: '12px',
                      fontWeight: 400,
                      color: '#868686',
                    }}
                  >
                    {`${item.requests} ${lang.requests}`}
                  </h6>
                </Box>
                <Box display='flex'>
                  {item.levels.map((el, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          height: 32,
                          width: 32,
                          backgroundColor: el.hide
                            ? '#3780FF'
                            : getColor(el.position),
                          borderRadius: 50,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#fff',
                          fontSize: '14px',
                          fontWeight: 400,
                          border: '1px solid #FFFFFFB2',
                          zIndex: item.levels.length - index,
                          ml: '3px',
                          position: 'relative',
                          '&::after':
                            itemIndex === 0
                              ? {
                                top: -16,
                                position: 'absolute',
                                content: `"${getFormatedDistance(
                                  el.distance,
                                )}"`,
                                fontSize: '12px',
                                fontWeight: 400,
                                color: '#868686',
                              }
                              : {},
                        }}
                      >
                        {el.hide ? '?' : el.position ?? '21+'}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </div>
          );
        })}
      </div>
    );
  }