import { Box } from '@mui/material';

export default function ReviewMarker(props: {
  lat: number;
  lng: number;
  hide: boolean;
  value?: number;
  color?: string;
  isCurrentPlace: boolean;
}) {
  const value = () => {
    return props.hide ? '?' : props?.value ?? '';
  };

  function getColor(value?: number) {
    if (!value || value <= 0) {
      return '#868686';
    } else if (value < 2) {
      return '#840000';
    } else if (value <3) {
      return '#B62727';
    } else if (value < 3.5) {
      return '#B14D37';
    } else if (value < 4) {
      return '#B16337';
    } else if (value < 4.4) {
      return '#B18037';
    } else if (value < 4.7) {
      return '#8AB137';
    } else if (value === 5) {
      return '#38B137';
    } else {
      return '#840000';
    }
  }

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      sx={{
        backgroundColor: getColor(props.value),
        borderRadius: 50,
        height: '24px',
        width: '24px',
        color: '#fff',
      }}
    >
      {value()}
    </Box>
  );
}
